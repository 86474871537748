// src/AboutSection.js
import React from 'react';
import './AboutSection.css';

const AboutSection = () => {
  return (
    <section className="about-section">
      <div className="about-text">
        <h2>About</h2>
        <p> Luke Turner is a passionate Game Developer who loves creating unforgettable experiences across through interactivity, across the XR and gaming spaces. </p>
      </div>
      <div className="about-image">
        <img src={`${process.env.PUBLIC_URL}/img/me.jpg`} alt="Neurogoblin Studios" />
      </div>
    </section>
  );
};

export default AboutSection;
